<template>
    <div>
        <section class="innerBanner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb">
                            <ul>
                                <li>
                                    <router-link to="">
                                        <span class="icon-home"></span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="">User Profile</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>My Profile</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3">
                        <ProfileCard />
                    </div>
                    <div class="col-xl-9">
                        <UserCounter />
                        <UserTab />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileCard from './partials/ProfileCard.vue';
import UserCounter from './partials/UserCounter.vue';
import UserTab from './partials/UserTab.vue'
export default {
    name: "UserProfile",
    components: {
        ProfileCard,
        UserCounter,
        UserTab
    }
}
</script>
<style src="../../assets/css/user.css"></style>